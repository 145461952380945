<template>
    <div id="choiceObject">
        <el-dialog class="choicePersonnelDialog" :title="title" :visible.sync="visible" :modal-append-to-body="false" @close="handleClose" :show-close="false">
            <div class="strrbox">
                <div class="titlebox">
                    <p>已选择 {{ currentOrgName }}</p>
                    <p></p>
                </div>
                <div class="leftBox">
                    <el-tree
                        :data="Treedata"
                        ref="tree_ref"
                        :props="defaultProps"
                        :expand-on-click-node="false"
                        :current-node-key="currentOrgId"
                        :node-key="defaultProps.id"
                        highlight-current
                        accordion
                        @node-click="handleNodeClick"
                        :default-expand-all="true"
                    >
                        <div :class="['my-custom-label', currentOrgId === data[defaultProps.id] ? 'current' : '']" slot-scope="{ node, data }">
                            <span v-html="handleSetFilterHighlight(node.label)"></span>
                        </div>
                    </el-tree>
                </div>
            </div>
            <div style="margin-left: 270px; margin-top: 10px">
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Button, Tree, Dialog } from "element-ui"
import { MessageBox, Message } from "element-ui"
export default {
    name: "choiceObject",
    components: {
        "el-button": Button,
        "el-tree": Tree,
        "el-dialog": Dialog,
    },
    data() {
        return {
            title: "选择用户",
            visible: false,
            multiple: false, //多选
            required: true, //是否必须至少选择一项
            originTreeData: [],
            Treedata: [],
            filterOrg: "",
            filterTimeout: null,
            currentOrgId: "",
            currentOrgName: "",
            defaultProps: {
                label: "groupName",
                children: "children",
                id:"groupId"
            },
            orgTimeout: null,
            selectedUsers: [], //回显数组 通过id,id没有就用realName
            // currentUserId: '',
            // currentUserRealname: '',
            // currentUsername: '',
            filterUser: "",
            userLoading: false,
            pagination: {
                pageNum: 1,
                pageSize: 100,
                total: 0,
            },
            userTableData: [],
            userTimeout: null,
            successCallback: null,
            defaultDisabledIds: [], //禁用选择id或realName 数组
        }
    },
    watch: {
        visible(val) {
            if (!val) {
                this.filterOrg = ""
                this.filterUser = ""
                this.currentOrgId = ""
                this.currentOrgName = ""
                this.selectedUsers = []
                this.Treedata = []
                this.userTableData = []
                this.defaultDisabledIds = []
                this.required = true
                this.multiple = false
            }
            else{
                this.getIscOrgTree();
            }
        },
        filterOrg(val) {
            if (!this.visible) return
            this.filterTimeout && window.clearTimeout(this.filterTimeout)
            this.filterTimeout = setTimeout(() => {
                this.handleFilter()
            }, 500)
        },
    },
    methods: {
        getIscOrgTree() {
      // /v1.0/iscuap/co/getIscOrgTree
      return new Promise(resolve => {
        this.api.getListAPILab(
          '',
          'common/select/getIscOrgTree'
        ).then(res => {
          this.Treedata = res.data.data || [];
          resolve(true);

        }).catch(error => {
          console.log('getIscOrgTree, error', error);
          resolve(false);
        })
      })
    },
        handleFilter() {
            let target = []
            let getChildren = (children, value) => {
                let temp = []
                if (!(children instanceof Array) || !children.length) return []
                for (let i = 0; i < children.length; i++) {
                    // 当前项是否匹配value   value为空''  也匹配
                    let include = children[i][this.defaultProps.label].includes(value)
                    // 当前项子集
                    let tempChildren = children[i][this.defaultProps.children]
                    // 若不匹配  且当前项子集children不存在 ，为叶子节点  直接continue
                    if (!include && (!(tempChildren instanceof Array) || !tempChildren.length)) continue
                    // 子集递归筛选   当前项  若匹配 则筛选字段为空  若不匹配 筛选为value
                    let subChildren = getChildren(tempChildren, include ? "" : value)

                    // 若当前项不匹配  且当前项子集children不存在  直接continue；
                    if (!include && !subChildren.length) continue
                    let suffix = {}
                    suffix[this.defaultProps.children] = subChildren
                    temp.push({
                        ...children[i],
                        ...suffix,
                    })
                }
                // console.log('temp', temp);
                return temp
            }
            target = getChildren(this.originTreeData, this.filterOrg)
            // console.log('handleFilter', target);
            this.Treedata = target
        },
        handleSetFilterHighlight(label) {
            if (!this.filterOrg) return label
            return label.replace(new RegExp(`${this.filterOrg}`, "g"), `<span class="highlight">${this.filterOrg}</span>`)
        },
        handleNodeClick(data) {
            this.currentOrgId = data[this.defaultProps.id]
            this.currentOrgName = data[this.defaultProps.label]
        },
        handleClose() {
            this.userTableData = []
            this.visible = false
        },
        handleSubmit() {
            if (!this.currentOrgId) {
                return MessageBox.alert("至少选择一项！", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                    customClass: "labMessageClass",
                })
                    .then(() => {})
                    .catch(() => {})
            }

            let data = {
                value: this.currentOrgId,
                label: this.currentOrgName,
            }
            if (typeof this.successCallback === "function") {
                this.successCallback(data)
            }
            this.visible = false
        },
    },
}
</script>
<style lang="less">
#choiceObject {
    & > .choicePersonnelDialog {
        & > .el-dialog {
            width: 500px;
            height: 560px;

            & div {
                box-sizing: border-box;
            }

            // & > .el-dialog__header {
            //     padding: 12px 10px !important;
            //     background-image: linear-gradient(538deg, var(--main-bg-color), #00653E) !important;

            //     & > .el-dialog__title {
            //         font-size: 16px !important;
            //         color: white !important;
            //         font-weight: bold !important;
            //     }

            //     & > .el-dialog__headerbtn {
            //         position: absolute;
            //         top: 20px;
            //         right: 20px;
            //         padding: 0;
            //         background: 0 0;
            //         border: none;
            //         outline: 0;
            //         cursor: pointer;
            //         font-size: 16px;

            //         & > .el-dialog__close {
            //             color: white !important;
            //             font-size: 21px !important;
            //             position: relative !important;
            //             top: -6px !important;
            //         }
            //     }
            // }
            & .el-dialog__header {
    flex-shrink: 0;
    padding: 15px 15px 10px !important;
    border-bottom: 2px solid #ebeef5;
    font-weight: bold;
    color: #303133;
    font-size: 14px;
    line-height: 1;
    position: relative;
    & .el-dialog__title{
      font-size: 14px;
    }

  }

            & > .el-dialog__body {
                height: calc(100% - 48px);
                display: flex;
                flex-flow: column nowrap;
                & .el-button--primary:focus, & .el-button--primary:hover{
			background :var(--btn-hover-color);
			border-color: var(--btn-hover-color);
		}

                & > .strrbox {
                    flex-grow: 1;
                    overflow: auto;
                    display: flex;
                    // width: 98% !important;
                    height: 100%;
                    margin: 20px 20px 0;

                    // margin-top: 30px;
                    & > .titlebox {
                        width: 70% !important;
                        position: absolute !important;
                        // top: 65px !important;
                        top: 65px !important;
                        height: 20px !important;

                        p:first-child {
                            width: 100% !important;
                            float: left !important;
                            height: 24px !important;
                            margin-right: 5% !important;
                            font-weight: 600 !important;
                            color: #000 !important;
                        }
                    }

                    & > .leftBox {
                        // width: 49% !important;
                        flex-grow: 1;
                        flex-shrink: 0;
                        min-width: 300px;
                        height: 100% !important;
                        overflow: hidden !important;
                        overflow-y: auto !important;
                        margin-right: 2% !important;
                        border: #ccc solid 1px !important;

                        & > .el-input {
                            margin: 15px 16px 10px;
                            width: calc(100% - 32px);

                            & > .el-input__inner {
                                &:focus,
                                &:hover {
                                    border: 1px solid #0a8a55;
                                }
                            }
                        }

                        & > .el-tree {
                            & .el-tree-node {
                                &.is-current {
                                    & > .el-tree-node__content {
                                        background: var(--main-bg-color) !important;
                                        color: #fff;

                                        & > .my-custom-label {
                                            & .highlight {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }

                                & > .el-tree-node__content {
                                    &:hover {
                                        background: var(--main-bg-color) !important;
                                        color: #fff;

                                        & > .my-custom-label {
                                            & .highlight {
                                                color: #fff;
                                            }
                                        }
                                    }

                                    & > .el-tree-node__expand-icon {
                                        &.is-leaf {
                                            background-color: unset !important;
                                        }
                                    }

                                    & > .my-custom-label {
                                        & .highlight {
                                            color: var(--main-bg-color);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & > .rightBox {
                        // width: 49% !important;
                        // flex-grow: 1;
                        width: 600px;
                        height: 100% !important;
                        overflow: hidden;
                        border: #ccc solid 1px !important;
                        padding: 15px 16px 20px;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: stretch;

                        & > .el-input {
                            flex-shrink: 0;

                            & > .el-input__inner {
                                &:focus,
                                &:hover {
                                    border: 1px solid var(--main-bg-color);
                                }
                            }
                        }

                        & > .el-table {
                            margin: 10px 0 5px 0;
                            flex-grow: 1;
                            overflow: auto;

                            & .cell {
                                &.selection:not(.multiple) {
                                    display: none;
                                }
                            }
                        }

                        & > .el-pagiantion {
                            flex-shrink: 0;
                        }
                    }
                }

                & > .el-button {
                    margin-right: 1%;
                    align-self: flex-end;
       
                }
            }
        }
    }
}
</style>
