<template>
  <div id="choiceStaff">
    <!-- v-dialogDrag -->
    <el-dialog class="choiceStaffDialog" :title="title" :visible.sync="visible" :modal-append-to-body="false"
      @close="handleClose" >
      <div class="strrbox">

        <el-input  class="staff-input"  v-model="filterUser" size="small" placeholder="请输入人员姓名"></el-input>
        <el-table ref="table_ref" :data="userTableData" border row-key="id" v-loading="userLoading"
          highlight-current-row  size="small"
          :header-cell-style="{ background: '#E9EEF5', color: '#000' }" :row-style="rowClass"
          @row-click="handleRowClick" @selection-change="handleSelectionChange">
          <el-table-column show-overflow-tooltip type="selection" :label-class-name="'selection'" label="dfd"
            :selectable="selectable" width="50"></el-table-column>
          <el-table-column show-overflow-tooltip type="index" label="序号" width="50">
            <template slot-scope="scope">
              {{ (pagination.pageNum - 1) * pagination.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="realName" label="姓名" width="100"></el-table-column>
          <el-table-column show-overflow-tooltip prop="jobTitle" label="职称">
            <template slot-scope="scope">
              <span v-show="scope.row.jobTitle == '01'">助理工程师</span>
              <span v-show="scope.row.jobTitle == '02'">初级工程师</span>
              <span v-show="scope.row.jobTitle == '03'">中级工程师</span>
              <span v-show="scope.row.jobTitle == '04'">高级工程师</span>

              <span v-show="scope.row.jobTitle == '05'">教授级高级工程师</span>
              <span v-show="scope.row.jobTitle == '06'">技师</span>
              <span v-show="scope.row.jobTitle == '07'">高级技师</span>

              <span v-show="scope.row.jobTitle == '99'">其他</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="post" label="岗位">
            <template slot-scope="scope">
              <span>
                {{ scope.row.post }}
              </span>

            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="orgName" label="所属部门"> </el-table-column>
        </el-table>
        <el-pagination @size-change="handleStaffSizeChange" :current-page="pagination.pageNum"
          @current-change="handleStaffCurrentChange" :page-sizes="[10, 20, 30, 50]" :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
      <div class="bottom-buttons">
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Button, Dialog } from "element-ui"
import { MessageBox, Message } from "element-ui"
export default {
  name: "ChoiceStaff",
  components: {
    "el-button": Button,
    "el-dialog": Dialog,
  },
  data() {
    return {
      title: "选择用户",
      visible: false,
      multiple: false, //多选
      required: true, //是否必须至少选择一项
      originTreeData: [],
      filterOrg: "",
      filterTimeout: null,
      currentOrgId: "",
      currentOrgName: "",
      orgTimeout: null,
      selectedUsers: [], //回显数组 通过id,id没有就用realName
      // currentUserId: '',
      // currentUserRealname: '',
      // currentUsername: '',
      filterUser: "",
      userLoading: false,
      pagination: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      userTableData: [],
      userTimeout: null,
      successCallback: null,
      defaultDisabledIds: [], //禁用选择id或realName 数组
    }
  },
  computed: {
    //岗位
    postOptions() {
      return [
        {
          value: '01',
          label: '检验人员',
        },
        {
          value: '02',
          label: '样品管理员',
        },
        {
          value: '03',
          label: '资料档案管理员',
        },
        {
          value: '04',
          label: '仪器设备管理员',
        },
        {
          value: '05',
          label: '内审员',
        },
        {
          value: '06',
          label: '监督员',
        },
        {
          value: '07',
          label: '检测部主任',
        },
        {
          value: '08',
          label: '技术负责人',
        },
        {
          value: '09',
          label: '质量负责人',
        },
        {
          value: '10',
          label: '授权签字人',
        },
        {
          value: '11',
          label: '最高管理者',
        },
        {
          value: '12',
          label: '检测人员',
        },
        {
          value: '13',
          label: '校准人员',
        },
        {
          value: '99',
          label: '其他',
        },
      ]
    },
  },
  watch: {
    visible(val) {
      console.log("choiceStaff, watch, visible", val, this.defaultProps, this.title)
      if (val) {
        this.getStaffTableData();
      } else {
        this.filterUser = ""
        this.currentOrgName = ""
        this.selectedUsers = []
        this.userTableData = []
        this.defaultDisabledIds = []
        this.required = true
        this.multiple = false
      }
    },
    filterUser(val) {
      // if (!val) return
      this.userTimeout && window.clearTimeout(this.userTimeout)
      this.userTimeout = setTimeout(() => {
        this.pagination = {
          ...this.$options.data().pagination,
        }
        this.getStaffTableData()
      }, 500)
    },
  },
  methods: {
    getStaffTableData() {
      this.userLoading = true;
      let data = {
        ...this.pagination,
        filters: [
          {
            fieldName: 'staffInfo.realname',
            operator: 'LIKE',
            connectOperator: 'AND',
            fieldValue: this.filterUser,
            groupName: '_S',
          },
          {
            fieldName: 'staffInfo.staffNo',
            operator: 'LIKE',
            connectOperator: 'OR',
            fieldValue: this.filterUser,
            groupName: '_S',
          },
        ],
        sorters: [
          {
            sortField: 'staffInfo.createTime',
            direction: 'DESC',
          },

        ],

      };
      this.api.postFormAPIStaff(
        data,
        "queryStaffInfo"
      ).then(res => {
        console.log('getStaffTableData', res)
        let tempData = res.data.data.records || [];
        // 处理岗位数据
        tempData = tempData.map(item => {
          let postIds = item.post.split(',');
          let post = postIds.map(id => {
            let option = this.postOptions.find(option => option.value === id);
            return option ? option.label : '';
          }).filter(label => label).join(', ');

          return {
            ...item,
            post,
          };
        });
        this.userTableData = tempData || [];
        this.pagination.total = res.data.data.total
        this.userLoading = false;
        this.selectedUsers.forEach(Item=>{
                    let findIndex = this.userTableData.findIndex(item => item.id ===Item.id);
                    if (findIndex > -1){
                        this.$nextTick(() => {
                          this.$refs.table_ref.toggleRowSelection(this.userTableData[findIndex], true);
                        })
                        
                    }
                })
      })
    },
    handleStaffSizeChange(pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.pageNum = 1
      this.getStaffTableData()
    },
    handleStaffCurrentChange(pageNum) {
      this.pagination.pageNum = pageNum
      this.getStaffTableData()
    },
    selectable(row) {
      return !this.defaultDisabledIds.includes(row.id) && !this.defaultDisabledIds.includes(row.realName)
    },
    handleRowClick(row) {
      console.log("handleRowClick", this.defaultDisabledIds, this.defaultDisabledIds.includes(row.id))
      if (!this.selectable(row)) {
        // this.$message({
        //     type: 'warning',
        //     message: `${ row.realName }不能同时是检测员和校核员`
        // });
        return
      }
      // if(!this.defaultDisabledIds.includes(row.id)){
      //     this.handleSelect("", row)
      // }
      // this.handleSelect("", row)
    },
    handleSelect(selection, row) {
      // 查询点击行 是否在selectedUsers 中
      let findIndex = this.selectedUsers.findIndex(item => (item.id && item.id === row.id) || (!item.id && item.realName === row.realName))
      console.log("handleSelect", row.id, findIndex)
      if (findIndex > -1) {
        // 点击或勾选的是 已存在 的行，则要取消
        if (!this.multiple) {
          //单选 要先清除其他勾选
          this.$refs.table_ref.clearSelection()
        }
        this.$refs.table_ref.toggleRowSelection(row, false)
        this.selectedUsers.splice(findIndex, 1)
      } else {
        // 点击或勾选的是 不存在 的行，则要勾选
        if (!this.multiple) {
          //单选 要先清除其他勾选
          this.$refs.table_ref.clearSelection()
          this.selectedUsers = []
        }
        this.$refs.table_ref.toggleRowSelection(row, true)
        this.selectedUsers.push({
          ...row,
        })
      }
    },
    handleSelectAll(selection) {
      if (!this.multiple) return
      let temp = this.selectedUsers.map(item => {
        return { ...item }
      })
      if (selection.length) {
        temp = [
          ...temp,
          ...selection.map(item => {
            return {
              ...item,
            }
          }),
        ]
        console.log("handleSelectAll", selection, temp)
        this.selectedUsers = temp.filter((item, index) => index === temp.findIndex(Item => (item.id && Item.id === item.id) || (!item.id && Item.realName === item.realName)))
        console.log("handleSelectAll.selectedUsers", this.selectedUsers)
      } else {
        this.selectedUsers = temp.filter((item, index) => index !== this.userTableData.findIndex(Item => (item.id && item.id === Item.id) || (!item.id && item.realName === Item.realName)))
      }
    },
    handleSelectionChange(selection)
    {
      this.selectedUsers = selection;
    },
    //表格选中背景色
    rowClass({ row }) {
      if (this.selectedUsers.findIndex(item => item.id === row.id) > -1) {
        return { "background-color": "#ECF5FF" }
      }
    },
    handleClose() {
      this.userTableData = []
      this.visible = false
    },
    handleSubmit() {
      console.log("handleSubmit", this.required)
      if (this.required && !this.selectedUsers.length) {
        return MessageBox.alert("至少选择一项！", "提示", {
          confirmButtonText: "确定",
          type: "warning",
          showClose: true,
          customClass: "labMessageClass",
        })
          .then(() => { })
          .catch(() => { })
      }

      let data = this.selectedUsers.map(item => {
        return {
          ...item,
        }
      })
      if (typeof this.successCallback === "function") {
        this.successCallback(data)
      }
      this.visible = false
      console.log("handleSubmit", data)
    },
  },
}
</script>
<style lang="less">
#choiceStaff {
  &>.choiceStaffDialog {
    &>.el-dialog {
      min-width: 1200px;
      height: 650px;

      & div {
        box-sizing: border-box;
      }

      // &>.el-dialog__header {
      //   padding: 12px 10px !important; 
      //   background-image: linear-gradient(538deg, var(--main-bg-color), #00653E) !important;

      //   &>.el-dialog__title {
      //     font-size: 16px !important;
      //     color: white !important;
      //     font-weight: bold !important;
      //   }

      //   &>.el-dialog__headerbtn {
      //     position: absolute;
      //     top: 20px;
      //     right: 20px;
      //     padding: 0;
      //     background: 0 0;
      //     border: none;
      //     outline: 0;
      //     cursor: pointer;
      //     font-size: 16px;

      //     &>.el-dialog__close {
      //       color: white !important;
      //       font-size: 21px !important;
      //       position: relative !important;
      //       top: -6px !important;
      //     }
      //   }
      // }
      & .el-dialog__header {
    flex-shrink: 0;
    padding: 15px 15px 10px !important;
    border-bottom: 2px solid #ebeef5;
    font-weight: bold;
    color: #303133;
    font-size: 14px;
    line-height: 1;
    position: relative;
    & .el-dialog__title{
      font-size: 14px;
    }

  }
      &>.el-dialog__body {
        height: calc(100% - 48px);
        display: flex;
        flex-flow: column nowrap;
        & .el-button--primary:focus, & .el-button--primary:hover{
			background :var(--btn-hover-color);
			border-color: var(--btn-hover-color);
		}
        &>.strrbox {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          // width: 98% !important;
          height: 100%;
          margin-top: 20px;
          & .staff-input{
            width: 100%;
    padding: 0 0 0 50%;
    margin: 0 0 10px;
    display: flex;
    justify-content: flex-end;
          }
          & .el-table {
            flex-flow: 1;
            overflow: auto;
          }

          & .el-pagination {
            flex-shrink: 0;
            margin: 10px 6px 10px 0;
            height: 34px;
            text-align: right;
          }

          // margin-top: 30px;
          &>.titlebox {
            width: 95% !important;
            position: absolute !important;
            top: 65px !important;
            height: 20px !important;

            p:first-child {
              width: 48% !important;
              float: left !important;
              height: 24px !important;
              margin-right: 5% !important;
              font-weight: 600 !important;
              color: #000 !important;
            }

            p:last-child {
              width: 47% !important;
              float: left !important;
              height: 24px !important;
              font-weight: 600 !important;
              color: #000 !important;
              margin-left: -80px !important;
            }
          }

          &>.leftBox {
            // width: 49% !important;
            flex-grow: 1;
            flex-shrink: 0;
            min-width: 500px;
            height: 100% !important;
            overflow: hidden !important;
            overflow-y: auto !important;
            margin-right: 2% !important;
            border: #ccc solid 1px !important;

            &>.el-input {
              margin: 15px 16px 10px;
              width: calc(100% - 32px);

              &>.el-input__inner {

                &:focus,
                &:hover {
                  border: 1px solid #0a8a55;
                }
              }
            }

            &>.el-tree {
              & .el-tree-node {
                &.is-current {
                  &>.el-tree-node__content {
                    background: var(--main-bg-color) !important;
                    color: #fff;

                    &>.my-custom-label {
                      & .highlight {
                        color: #fff;
                      }
                    }
                  }
                }

                &>.el-tree-node__content {
                  &:hover {
                    background: var(--main-bg-color) !important;
                    color: #fff;

                    &>.my-custom-label {
                      & .highlight {
                        color: #fff;
                      }
                    }
                  }

                  &>.el-tree-node__expand-icon {
                    &.is-leaf {
                      background-color: unset !important;
                    }
                  }

                  &>.my-custom-label {
                    & .highlight {
                      color: var(--main-bg-color);
                    }
                  }
                }
              }
            }
          }

          &>.rightBox {
            // width: 49% !important;
            // flex-grow: 1;
            width: 600px;
            height: 100% !important;
            overflow: hidden;
            border: #ccc solid 1px !important;
            padding: 15px 16px 20px;
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;

            &>.el-input {
              flex-shrink: 0;

              &>.el-input__inner {

                &:focus,
                &:hover {
                  border: 1px solid #0a8a55;
                }
              }
            }

            &>.el-table {
              margin: 10px 0 5px 0;
              flex-grow: 1;
              overflow: auto;

              & .cell {
                &.selection:not(.multiple) {
                  display: none;
                }
              }
            }

            &>.el-pagiantion {
              flex-shrink: 0;
            }
          }
        }
       & .bottom-buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
           width: 100%;
         
      }
        &>.el-button {
          margin-right: 1%;
          align-self: flex-end;
        }
      }
    }
  }
}
</style>
