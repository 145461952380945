<template>
    <!-- <el-dialog :class="['my-dialog', 'device-wrapper', editable ? 'editable' : '']" :title="title"  :visible.sync="visible" width="70%"
        :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="false" @close="handleClose" 
    > -->
    <BasicDialog :class="['my-dialog', 'device-wrapper', editable ? 'editable' : '']" width="70%" :closeOnClickModal="false" :title="title"  :visible.sync="visible" @confirm="handleSubmitDevice" @close="handleClose">
        <div class="search-wrapper custom-input">
            <el-input size="small" v-model="queryData" placeholder="请输入设备控制编号或设备名称或型号/规格或保管人或出厂编号"
                @keyup.enter.native="handleDeviceSearch"></el-input>
            <el-button  type="primary" size="small" @click="handleDeviceSearch">查询</el-button>
        </div>

        <div class="table-body">
            <el-table ref="table_ref" :data="tableData" border v-loading="table_loading"
                highlight-current-row @select="handleSelectDeviceRow"   @selection-change="handleSelectionChange">
                @row-click="handleClickDeviceRow" @select-all="handleSelectDeviceAll"
                :header-cell-style="{ background: '#E9EEF5', color: '#000' }" :row-style="rowClass"
            >
                <el-table-column show-overflow-tooltip type="selection" width="45">
                </el-table-column>
                <el-table-column show-overflow-tooltip type="index" label="序号" width="50">
                    <template slot-scope="scope">
                        {{ (pagination.pageNum - 1) * pagination.pageSize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="deviceNo" label="设备控制编号" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                            scope.row.deviceNo }}</span>
                        <span v-else>{{ scope.row.deviceNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="deviceName" label="设备名称">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                            scope.row.deviceName }}</span>
                        <span v-else>{{ scope.row.deviceName }}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="deviceModel" label="型号/规格" width="155">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                            scope.row.deviceModel }}</span>
                        <span v-else>{{ scope.row.deviceModel }}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="productNo" label="出厂编号" width="160">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                            scope.row.productNo }}</span>
                        <span v-else>{{ scope.row.productNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="realName" label="保管人" width="90">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                            scope.row.realName }}</span>
                        <span v-else>{{ scope.row.realName }}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="orgName" label="所属部门" width="240">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                            scope.row.orgName }}</span>
                        <span v-else>{{ scope.row.orgName }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleDeviceSizeChange" :current-page="pagination.pageNum"
                @current-change="handleDeviceCurrentChange" :page-sizes="[10, 20, 30, 50]"
                :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="pagination.total">
            </el-pagination>
        </div>

        <!-- 可编辑选中的设备   例如 设备期间核查计划-->
        <template v-if="editable">
            <div class="action-button">
                <el-button type="primary" size="small"  icon="el-icon-bottom" @click="handleSelectDevice"> 添加 </el-button>
            </div>
            <div class="table-selected">
                <el-table border ref="device_table_ref" :data="deviceTableData">
                    <!-- <el-table-column show-overflow-tooltip type="selection" width="45"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                    <el-table-column show-overflow-tooltip prop="deviceNo" label="设备控制编号" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                                scope.row.deviceNo }}</span>
                            <span v-else>{{ scope.row.deviceNo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="deviceName" label="设备名称">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                                scope.row.deviceName }}</span>
                            <span v-else>{{ scope.row.deviceName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="deviceModel" label="型号/规格" width="155">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                                scope.row.deviceModel }}</span>
                            <span v-else>{{ scope.row.deviceModel }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="productNo" label="出厂编号" width="160">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                                scope.row.productNo }}</span>
                            <span v-else>{{ scope.row.productNo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="realName" label="保管人" width="90">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                                scope.row.realName }}</span>
                            <span v-else>{{ scope.row.realName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="orgName" label="所属部门" width="170">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isSourceResult == '1'" style="color: blue">{{
                                scope.row.orgName }}</span>
                            <span v-else>{{ scope.row.orgName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="manager" :label="maintainerLabel" width="120">
                        <template slot-scope="scope">
                            <el-input size="mini" class="table_input" placeholder="请输入姓名"
                                v-model="scope.row.manager"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="planDate" label="计划日期" width="130">
                        <template slot-scope="scope">
                            <!-- value-format="yyyy-MM-dd"  type="date" -->
                            <el-date-picker size="mini" value-format="yyyy-MM" v-model="scope.row.planDate" type="month" placeholder="选择日期">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" width="130">
                        <template slot-scope="scope">
                            <el-input size="mini" class="table_input" placeholder="请输入备注" v-model="scope.row.remark"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <span class="btn_hover ml-0" @click="handleDeviceRemove(scope.row)">移除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>
        
        <!-- <div class="action-button">
            <el-button class="my-button-green-1" @click="handleSubmitDevice">确 定</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </div> -->
    </BasicDialog>
    <!-- </el-dialog> -->
</template>
<script>
    import { Dialog, Input, Button, Table, TableColumn, Pagination } from 'element-ui';
    import store from '../../store';
    export default {
        name: 'DeviceWrapper',
        components: {
            // 'el-dialog': Dialog,
            // 'el-input': Input,
            // 'el-button': Button,
            // 'el-table': Table,
            // 'el-table-column': TableColumn,
            // 'el-pagination': Pagination,
        },
        data(){
            return {
                title: '设备期间核查计划',
                // 是否可编辑  默认true 会在下方展示可编辑列的选中设备，例如 设备期间核查计划
                // false 只需要勾选原设备即可  例如 检测能力-新建-检测项目变更-选择设备
                editable: true,
                // 获取设备是否需要用户角色过滤  后端做处理
                // 默认需要 接口使用queryDevice
                // 检测能力-新建-检测项目变更-选择设备  不需要后端过滤 使用新接口queryDevices  查询所有设备
                roleFilter: true,

                visible: false,
                maintainerLabel: '负责人',
                queryData: '',
                tableData: [],
                table_loading: false,
                pagination: {
                    pageNum: 1,
                    pageSize: 15,
                    total: 0,
                },
                selectedData: [], //原始设备  勾选的
                isRunning:false,
                deviceTableData: [],  //已经添加的设备
                // selectedDeviceData: [], //对  添加的设备进行勾选的

                handleSelectRow: () => {}, //行勾选
                handleClickRow: () => {}, //行点击
                successCallback: null, //成功回调

            }
        },
        computed: {
            userInfo(){
                return store.state.userInfo;
            },
        },
        watch: {
            visible(val){
                console.log('watch, visible', val);
                if (val){
                    this.handleDeviceSearch();
                } else {
                    this.queryData = '';
                    this.tableData = [];
                    this.selectedData = [];
                    this.deviceTableData = [];
                }
            },
        },
        methods: {
            // 获取弹窗设备列表
            getDeviceTableData() {
                this.table_loading = true;
                this.api.postFormAPIDevice(
                    {
                            ...this.pagination,
                            filters: [
                              {
                                    fieldName: "deviceInfo.tenantId",
                                    operator: "LIKE",
                                    connectOperator: "AND",
                                    fieldValue: this.userInfo.tenantId,
                                    groupName: "",
                                },
                           ...this.isRunning?[{
                                    fieldName: "deviceInfo.deviceState",
                                    operator: "EQ",
                                    connectOperator: "AND",
                                    fieldValue: '01',
                                    groupName: "",
                                }]:[],
                                {
                                    fieldName: "deviceInfo.deviceName",
                                    operator: "LIKE",
                                    connectOperator: "AND",
                                    fieldValue: this.queryData,
                                    groupName: "_s",
                                },
                                {
                                    fieldName: "deviceInfo.deviceNo",
                                    operator: "LIKE",
                                    connectOperator: "OR",
                                    fieldValue: this.queryData,
                                    groupName: "_s",
                                },
                                {
                                    fieldName: "deviceInfo.deviceModel",
                                    operator: "LIKE",
                                    connectOperator: "OR",
                                    fieldValue: this.queryData,
                                    groupName: "_s",
                                },
                                {
                                    fieldName: "deviceInfo.realName",
                                    operator: "LIKE",
                                    connectOperator: "OR",
                                    fieldValue: this.queryData,
                                    groupName: "_s",
                                },
                                {
                                    fieldName: "deviceInfo.productNo",
                                    operator: "LIKE",
                                    connectOperator: "OR",
                                    fieldValue: this.queryData,
                                    groupName: "_s",
                                },
                        
                            ],
                            sorters: [
                                {
                                    sortField: "deviceInfo.createTime",
                                    direction: "DESC",
                                },
                            ],

                    },
                    "queryDevice"
                    // this.roleFilter ? "queryDevice" : 'queryDevices'
                )
                .then((res) => {
                    this.table_loading = false;
                    this.tableData = res.data.data.records || [];
                    this.pagination.total = res.data.data.total;
                    this.$nextTick(() => {
                        console.log('deviceTableDataIds',this.deviceTableData);
                        let deviceTableDataIds = this.deviceTableData.map(item => item.id);
                        let temp = [];
                        for (let i = 0; i < this.tableData.length; i++){
                            if (deviceTableDataIds.includes(this.tableData[i].id)){
                                this.$refs.table_ref.toggleRowSelection(this.tableData[i], true);
                                temp.push({ ...this.tableData[i] })
                            }
                        }
                        this.selectedData = temp;
                    })
                })
                .catch((err) => console.log(err));
            },
            handleDeviceSearch(){
                this.pagination = {
                    ...this.pagination,
                };
                this.getDeviceTableData();
            },
            handleDeviceSizeChange(pageSize){
                this.pagination.pageSize = pageSize;
                this.getDeviceTableData();
            },
            handleDeviceCurrentChange(pageNum){
                this.pagination.pageNum = pageNum;
                this.getDeviceTableData();
            },
            // 点击多选切换
            handleSelectDeviceAll(){
                for (let i = 0; i < this.tableData.length; i++){
                 
                }
            },
            // 多选框点击
            handleSelectDeviceRow(selection, row){
                // let findIndex = this.selectedData.findIndex(item => item.id === row.id);
                // if (findIndex === -1){
                //     // 勾选不存在的  push
                //     this.selectedData.push({ ...row })
                // } else {
                //     // 已存在的  取消勾选 取消
                //     this.selectedData.splice(findIndex, 1);
                // }

                // // 额外加的方法  在检测能力-新建-检测项目变更/扩项-选择设备  需要交互
                // this.handleSelectRow(selection, row);
            },
            // 行点击
            handleClickDeviceRow(row){
                // this.handleSelectDeviceRow(null, row); //模拟单选框点击
                // this.$refs.table_ref.toggleRowSelection(row); //反选勾选
            },
            handleSelectionChange(selection)
            {
                this.selectedData = selection;
            },
            // 点击添加按钮
            handleSelectDevice(){
                let deviceTableDataIds = this.deviceTableData.map(item => item.id);
                for (let i = 0; i < this.selectedData.length; i++){
                    if (deviceTableDataIds.includes(this.selectedData[i].id)) continue;
                    this.deviceTableData.push({ ...this.selectedData[i] });
                }
                console.log('handleSelectDevice', this.selectedData, this.deviceTableData);

                
            },
            handleDeviceRemove(row){
                let findIndex = this.deviceTableData.findIndex(item => item.id === row.id);
                this.deviceTableData.splice(findIndex, 1);
                this.getDeviceTableData();
            },
            handleSubmitDevice(){
                // 可编辑
                if (this.editable){
                    if (!this.deviceTableData.length) {
                        return this.$message.warning("请先添加设备");
                    }
                    typeof this.successCallback === 'function' && this.successCallback(this.deviceTableData);
                } else {
                    if (!this.selectedData.length) {
                        return this.$message.warning("请先选择设备");
                    }
                    typeof this.successCallback === 'function' && this.successCallback(this.selectedData);
                }
                
            },
            rowClass({ row }) {
                if (this.selectedData.findIndex(item => item.id === row.id) > -1) {
                    return { "background-color": "#ECF5FF" };
                }
            },
            handleClose(){
                this.$emit('handleCloseDeviceDialog');
                this.visible = false;
            }
        }
    }
</script>
<style lang="less" >
    .my-dialog.device-wrapper{
        &  .el-dialog{
            // margin-top: 8vh !important;
            height: 85%;
            &.is-fullscreen{
                height: 100%;
            }
            // overflow: hidden!important;
            left: 64px;
            &>.el-dialog__body{
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: auto 1fr auto;
                overflow: auto;
                & .el-button--primary:focus, & .el-button--primary:hover{
			background :var(--btn-hover-color);
			border-color: var(--btn-hover-color);
		}
                &>.search-wrapper{
                    width: 100%;
                    padding: 0 0 0 50%;
                    margin: 0 0 10px;
                    display: flex;
                    justify-content: flex-end;
                }
                &>.table-body{
                    display: flex;
                    flex-flow: column nowrap;
                    overflow: hidden;
                    &>.el-table{
                        flex-grow: 1;
                        overflow-y:auto;
                      
                        &  .el-table__body-wrapper{
                            overflow-y:auto !important; 
                            &::-webkit-scrollbar{
                                width: 0;
                            }
                          
                        }
                    }
                    &>.el-pagination{
                        flex-shrink: 0;
                        margin: 10px 6px 10px 0;
                        height: 34px;
                        text-align: right;
                    }
                }
                &>.action-button{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin: 10px 0;
                    &>.my-button-plain{
                        height: auto;
                        border-radius: 4px;
                        padding: 10px 16px!important;
                    }
                    &>.my-button-green-1{
                        height: auto;
                        padding: 10px 20px!important;
                        font-size: 14px;
                    }
                }
                &>.table-selected{
                    &>.el-table{
                        &  .el-table__body-wrapper{
                            overflow-y:auto !important; 
                            height: 150px;
                            &::-webkit-scrollbar{
                                width: 0;
                              
                            }
                            // height: 200px;
                        }
                    }
                }
            }
            &>.el-dialog__footer{
                text-align: right;
            }
        }
        &.editable{
            &  .el-dialog__body{
                grid-template-rows: auto minmax(300px,55%) auto minmax(150px,1fr) auto;
            }
        }

      
    }
</style>