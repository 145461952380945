<template>
  <el-dialog
    class="my-dialog upload-wrappers"
    :title="title"
    :visible.sync="visible"
    width="900px"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :append-to-body="false"
    @close="handleClose"
  >
    <el-upload
    ref="upload_files_ref"
    action="#"
    :accept="acceptGeneral"
    :multiple="true"
    :show-file-list="false"
    :before-upload="beforeGeneralUpload"
    :http-request="handleUploadFile"
    >
    <button class="my-button-plain mb-10 pl-10 pr-10">上传</button>
    </el-upload>
    <el-table class="mb-10"
    ref="table_ref"
    :data="fileList"
    border
    highlight-current-row
    :header-cell-style="{ background: '#E9EEF5', color: '#000' }"
    >
    <el-table-column type="index" label="序号" width="50"></el-table-column>
    <el-table-column prop="fileName" label="文件名"></el-table-column>
    <el-table-column label="操作" width="120" class-name="cell-flex">
        <template slot-scope="scope">
          <span class="btn_hover type--text" style="margin-left:0px !important" @click="handlDownloadFile(scope.row)">下载</span>
          <span class="btn_hover type--text" @click="handleDelFile(scope.row)">删除</span>
        </template>
    </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { Dialog, Input, Button, Table, TableColumn } from 'element-ui'
import mixin from '@/utils/mixin'
export default {
  name: 'StaffWrapper',
  mixins: [mixin],
  components: {
    'el-dialog': Dialog,
    'el-input': Input,
    'el-button': Button,
    'el-table': Table,
    'el-table-column': TableColumn,
  },
  data() {
    return {
      title: '上传文件',
      buttonSuffixText:'',
      visible: false,
      fileList: [],
      table_loading: false,
      uploadCallback: null, //上传回调
      downloadCallback:null,  //下载回调
      deleteCallback:null,  //删除回调

    }
  },
  watch: {
    visible(val) {
      console.log('watch, visible', val)
      if (val) {

      } else {
        this.fileList = [];
        this.buttonSuffixText='';
      }
    },
  },
  methods: {
    async handleUploadFile({ file }) {
        if(typeof this.uploadCallback === 'function'){
            this.table_loading = true;
            this.fileList = await this.uploadCallback(file);
            this.table_loading = false;
        }
    },
    handlDownloadFile(row) {
        console.log('handlDownloadFile',row);
        if(typeof this.downloadCallback === 'function'){
           this.downloadCallback(row)
        }
    },
    async handleDelFile(row){
        console.log('handleDelFile',row);
        if(typeof this.deleteCallback === 'function'){   
            this.fileList = await this.deleteCallback(row)
        }
    },
    handleClose() {
      this.visible = false
    },
  },
}
</script>
<style lang="less">
.my-dialog.upload-wrappers {
  & > .el-dialog {
    height: 70%;
    overflow: auto !important;
    // &>.el-dialog__header {
    //     padding: 12px 10px !important;
    //     background: var(--main-bg-color) !important;

    //     &>.el-dialog__title {
    //       font-size: 16px !important;
    //       color: white !important;
    //       font-weight: bold !important;
    //     }

    //     &>.el-dialog__headerbtn {
    //       position: absolute;
    //       top: 20px;
    //       right: 20px;
    //       padding: 0;
    //       background: 0 0;
    //       border: none;
    //       outline: 0;
    //       cursor: pointer;
    //       font-size: 16px;

    //       &>.el-dialog__close {
    //         color: white !important;
    //         font-size: 21px !important;
    //         position: relative !important;
    //         top: -6px !important;
    //       }
    //     }
    //   }
    & > .el-dialog__body {
      // display: grid;
      // grid-template-columns: 100%;
      // grid-template-rows: auto 1fr auto;
      overflow: auto;
      & .el-button--primary:focus, & .el-button--primary:hover{
			background :var(--btn-hover-color);
			border-color: var(--btn-hover-color);
		}
      & > .upload-wrapper {
        width: 100%;
        padding-left: 100px;
        margin: 0 0 10px;
        display: flex;
      }
      & > .table-body {
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        & > .el-table {
          flex-grow: 1;
        }
        & > .el-pagination {
          flex-shrink: 0;
          margin: 10px 6px 10px 0;
          height: 34px;
          text-align: right;
        }
      }
      & > .action-button {
        text-align: center;
        margin: 10px 0;
        & > .my-button-plain {
          height: auto;
          border-radius: 4px;
          padding: 10px 16px !important;
        }
        & > .my-button-green-1 {
          height: auto;
          padding: 10px 20px !important;
          font-size: 14px;
        }
      }
      & > .table-selected {
        & > .el-table {
          height: 100%;
        }
      }
    }
  }
  &.editable {
    & .el-dialog__body {
      grid-template-rows: auto minmax(300px, 55%) auto minmax(150px, 1fr) auto;
    }
  }
}
</style>